<template>
    <comp-table title="终端运行状态" ref="comp_table" :columns="columns" :table-api="table_api">
        <template slot="operate">
            <Button type="primary" style="margin-right: 10px" @click="$router.push('/terminal_manage')">返回</Button>
        </template>
    </comp-table>
</template>

<script>
import CompTable from "../../../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            columns: [
                {
                    title: "UUID",
                    key: "terminalUuid",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "终端名称",
                    key: "terminalName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "心跳时间",
                    minWidth: 300,
                    align: "center",
                    render: (h, params) => {
                        if (!params.row.heartbeatTime) {
                            return h("div", {}, "-")
                        }

                        return h("div", {}, this.$core.formatDate(new Date(params.row.heartbeatTime), "yyyy-MM-dd hh:mm:ss"))
                    },
                },
                {
                    title: "运行状态",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                attrs: {
                                    style: `color:${params.row.color}`,
                                },
                            },
                            params.row.heartbeatStatus === 0 ? "正常" : "异常"
                        )
                    },
                },
                {
                    title: "运行描述",
                    key: "heartbeatDesc",
                    minWidth: 300,
                    align: "center",
                },
            ],

            table_api: null,
        }
    },

    mounted() {
        this.table_api = `/gateway/apps/o2o/api/terminal/listTerminalHeartbeatByPage?uuid=${this.$core.getUrlParam("uuid")}&terminalGroupId=${this.$core.getUrlParam("terminalGroupId")}`
    },

    methods: {},
}
</script>
<style lang="less"></style>
